#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.site-layout .site-layout-background {
  background: #fff;
}
.ant-layout-header {
  padding-left: 20px!important;
}
.ant-layout {
  min-height: 100vh!important;
}
@media only screen and (max-width: 1200px) {
  .ant-layout {
    max-height: 100vh;
    height: 100vh;
    min-height: 1vh!important;
  }
}
.ant-menu-inline-collapsed {
  width: 50px!important;
}
.ant-input-number {
  width: 100%!important;
  text-align: right!important;
}
.ant-input-number-input {
  text-align: right!important;
  padding-right: 25px!important;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(255, 255, 255, 0.85) !important;
  background: #f3f3f3 !important;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-modal-title {
  margin: 0;
  color: rgba(45, 45, 45, 0.85) !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #db3fd6 !important;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #ffffff !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1976d2 !important;
  color: #ffffff !important;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  background: #374a5c !important;
  color: #e3e3e3 !important;
  height: 40px;
  font-size: 15px!important;
  font-weight: bold!important;
  padding: 9px!important;
  border-bottom: 10px solid #7ab5ff;
}
