/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#mirko1:active {
  background-color: yellow;
}


@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/righteous/v13/1cXxaUPXBpj2rGoU7C9WiHGA.ttf) format('truetype');
}
@font-face {
  font-family: 'Bakbak One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bakbakone/v6/zOL54pXAl6RI-p_ardnu-cNkuw.ttf) format('truetype');
}
body {
  color: rgba(56, 56, 56, 0.85);
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.footer-link {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  cursor: pointer;
}
.header-gradient {
  background: #001529 !important;
  background: linear-gradient(54deg, #001529 0%, rgba(0, 21, 41, 0) 100%) !important;
}
.themepickers {
  position: fixed;
  top: calc(100vh - 40px);
  text-align: center;
  font-size: 9px;
  width: 200px;
}
.themecircle {
  cursor: pointer;
  border: 1px solid #808080;
  display: inline-block;
  width: 10px;
  height: 10px;
}
.themecirclelight {
  cursor: pointer;
  border: 1px solid #808080;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: white;
}
.themecircledark {
  cursor: pointer;
  border: 1px solid #808080;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #020a25;
}
.select-selected {
  font-weight: bold!important;
}
.pill {
  background-color: #f1f0f0 !important;
  padding: 5px!important;
  border-radius: 4px!important;
  border: 1px solid #cfcfcf;
}
.predmeti-grid {
  width: 100%;
  height: calc(100vh - 250px) !important;
}
.desktop-trash {
  width: 100px;
  height: 100px;
  position: fixed;
  left: calc(100vw - 100px);
  top: calc(100vh - 100px);
}
.ant-layout-content {
  overflow: auto;
}
.start-button {
  border: 1px solid #e5e5e5;
  width: 140px;
  height: 140px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  font-weight: bold;
  /* color: #ffffff; */
  background: #ffffff;
  cursor: pointer;
  box-shadow: 5px 5px #e3e5e9;
  transition: all 0.2s ease-in-out;
}
.button-container {
  height: 100%;
  width: 100%;
}
.container-start-button {
  border: 1px solid #e5e5e5;
  width: 140px;
  height: 140px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  font-weight: bold;
  /* color: #ffffff; */
  background: #ffffff;
  cursor: pointer;
  box-shadow: 5px 5px #e3e5e9;
  transition: all 0.2s ease-in-out;
  margin: 10px;
}
.colorhidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.grid-4 {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.start-button:active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -kthtml-transition: all 0.1s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.spinner {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  /* -webkit-animation: fadein 0.5s; */
  /* Safari, Chrome and Opera > 12.1 */
  /* -moz-animation: fadein 0.5s; */
  /* Firefox < 16 */
  /* -ms-animation: fadein 0.5s; */
  /* Internet Explorer */
  /* -o-animation: fadein 0.5s; */
  /* Opera < 12.1 */
  animation: fadein 0.5s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.548);
  color: #d1d1d1;
  z-index: 9999;
}
.spinner-icon {
  font-size: 80px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 130px;
}
.padding-grid .MuiGrid-root.MuiGrid-item:not(.MuiGrid-container) {
  padding: 5px!important;
}
.fadein {
  /* -webkit-animation: fadein 0.5s; */
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.5s;
  /* Internet Explorer */
  -o-animation: fadein 0.5s;
  /* Opera < 12.1 */
  animation: fadein 0.5s;
}
/* .MuiFormControl-root {
    margin-top: 20px !important;
} */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fc .fc-daygrid-day.fc-day-today {
  /* background-color: rgba(255, 220, 40, 0.15); */
  /* background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15)); */
  background: #e7e7e7 !important;
}
.fc-daygrid-day-number {
  color: #252525 !important;
}
.fc-col-header-cell-cushion {
  color: #252525 !important;
}
.fc .fc-button-primary {
  color: #0c0c0c !important;
  background-color: #dfdfdf !important;
  border-color: #cdcdcd !important;
}
.fc .fc-button-primary:focus {
  outline: 0!important;
}
.fc .fc-button-primary:disabled {
  color: #0c0c0c !important;
  background-color: #dfdfdf !important;
  border-color: #cdcdcd !important;
}
*:focus {
  outline: 0!important;
}
.fc-h-event {
  border: 1px solid #09506e !important;
  background-color: #1976d2 !important;
}
.fc .fc-daygrid-day.fc-day-today {
  /* background-color: rgba(255, 220, 40, 0.15); */
  /* background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15)); */
  background: #ffffff !important;
}
.fc-day-today .fc-daygrid-day-number {
  background: #1976d2;
  color: white;
  color: white!important;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 3px;
  text-align: center;
}
.fc .fc-daygrid-day-frame {
  cursor: pointer;
}
.fadein {
  -webkit-animation: fadein 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.5s;
  /* Internet Explorer */
  -o-animation: fadein 0.5s;
  /* Opera < 12.1 */
  animation: fadein 0.5s;
}
/* .MuiFormControl-root {
    margin-top: 20px !important;
} */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.list-item {
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
.list-title {
  background-color: #f5f5f5 !important;
  border-radius: 15px 15px 0px 0px;
  font-weight: bold;
}
.modl {
  border: 1px solid gray!important;
  /* width */
}
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.css-rs2hw4 {
  border: 1px solid gray!important;
  /* width */
}
.css-f5zd38 {
  border: 1px solid gray!important;
  /* width */
}
.css-erg65y {
  border: 1px solid gray!important;
  /* width */
}
.css-oi3vm8 {
  border: 1px solid gray!important;
  /* width */
}
